import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
// import VueSpeech from 'vue-speech'
import { mainStore, userToolStore, livroStore, } from '@/utils/store-accessor';
let Glossary = class Glossary extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.headers = [
            {
                text: 'Palavra',
                sortable: true,
                value: 'palavra',
                align: 'left',
            },
            {
                text: 'Tradução',
                value: 'traducao',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.notFoundImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADDCAMAAACxkIT5AAAASFBMVEVUVFSoqKhOTk5RUVGrq6tzc3OZmZmFhYWwsLBMTEyXl5etra1vb29+fn6Li4uTk5NiYmJnZ2dbW1uhoaGAgIBeXl5ycnJsbGwxLNHVAAAEr0lEQVR4nO2cDZeiIBSG4V4ElQSVJv//P13ATPuY2Wlqzy7u+5wzk0l2uk9cQEOEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFAaTO+H/3ZQz8GTfj9TWRK4svLd2Ko0B+rtDhQcwAEcwAEcwAEcwAEcwAEcwMGuHahuiYLMXbly9OXBcLBDBwwHcCDgIJfDARyI/9tBwzPNIweXwj07kK1Z0PeF+lLo9+xAqoWnC/fj4BXgAA7gAA7gAA7gAA7gAA7gAA7gAA7gAA7g4P90kK6a2ZnPL6Dt10EMXgbvuuowDcMwHarO+SDt1yL25CDG77tepMn4K/GZ6DsfPezfgbLBDCn6RwdFE4MJn2nYiQNl/dQ8jP9yIDeTf2xhFw6UcuM3bkZhGt2jlmEPDqwf6fdHZmj09/eAlO/A6v7GwNwSnm9Vug2Pen1roXQHSlZXWRCjF0N1dL7VWrfeHavYUfD1Kyqp9uTAtuPm8zOJg8vDgXPa59GS1GbirSgeW7sfB7Zr1iKi0yfDgDRwOGwtNN1WQskOlFxbgtjmmy/HQVaaTd9B/SYfCnagtNjE5H97YhDHEBtnQq9vVKwD6y8BpR7vO6eTatuL0qWXLNaBdUtTwGS+cXJ4DleZS0I0zpbtQJlFAQ3hmUsKKgxLVVjmLhXrYAmEzLM3/1pzObZwB/PHZvFUJViqwnlQwXtwcNXFPSFBzvmwBwd0+OnFRXVKEnbggF5YB8BWtAcHryhIw2wq38FrCnJNKN0BTa8uiGGn0vvGj9d/a1neo1QHP+oT7yTIsh28ETiAAziAAziAAziAAziAAzjYiwOsIclTePtSoqGwtUSxpiwAAABwQ5p8lx7m31wpTzjj607u/DRv8byT55/b0/a6gx9OYPz34aH1KWrXsWDhggyG+aDbjB5yeNP85EPQSUvZDhSP0ulg8h2za0+clm2n+FZt683tDM9/Hz5YeWRBMXQxqlBNnQw8GGNCMMb1+SWVTeu/uJFM7aaDryvmU51KKDimEGR0cKobPtTHo9O1L01CdFApkR2QDrEm06hcfGhav8xbj6dVnO9gGOqqSasF2TGGm0ryUaGTsTacbHIQs6GZ1nkphRAdNNJQjIbGOp/rkJEpy1u/WQkqZTqTC/OcJdvRlYNOLQ7yzs7+lUh+TnJQ1SmaGNeY90zp8cqB9ZFDE+ZdMQOaKwckj7Q6EGN9KKtdjA4oBRUdVHMIscr3tw6cc8nBvEgYaX/joLKbeiBEXdq5c3SQPrw2NNUf8567enDOhbUeXOdC/DPT6uCjHspzIOI3HB2IOq8UR+6uPZgTPBbkXaOtaMh5E5MgO4i1oLo4aIwqS8HsgAebvlEvx/ht9/XxsQPua5P6BS/jE3tsOJqYsoMoMahzv0BdaamQan6OuI7jAxGU6VzdpuBj0t86iC1+rbtjsLGq87F2nUlTnEmm0dVkcz3wvs0Tkwqjd5z/p7acu1b7ah47d+u3OZjzBvVOazPO0/i8blPu8DFdOaNjdNinoVQlCqsFifNAKD/weiFsO+6/bG7Ll01e/+ebQP/8JwYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwB/gFh35K7dZjp9sAAAAASUVORK5CYII=';
        this.listeningLivroDialog = false;
        this.loading = false;
        this.livros = [];
        this.livro = {};
        this.livro_codigo = '';
        this.currentUrl = '';
        this.tab = null;
        this.speaking = false;
        this.speakingItem = null;
        this.voiceDialog = false;
        this.lastSentence = '';
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_delete;
        }
    }
    get palavrasTraduzidas() {
        return mainStore.palavrasTraduzidas;
    }
    onEnd({ lastSentence, transcription }) {
        if (this.speaking) {
            this.lastSentence = lastSentence;
            if (this.speakingItem.palavra.toLowerCase() === lastSentence.toLowerCase()) {
                this.toggleSpeak();
            }
        }
    }
    async onListening(item) {
        this.currentUrl = item.url_audio;
        this.listeningLivroDialog = true;
        // const audio = new Audio(item.url_audio);
        // audio.play()
    }
    screenSize() {
        // @ts-ignore
        switch (this.$vuetify.breakpoint.name) {
            case 'xs':
                return 220;
            case 'sm':
                return 400;
            case 'md':
                return 500;
            case 'lg':
                return 600;
            case 'xl':
                return 800;
        }
    }
    toggleSpeakDialog(item) {
        this.voiceDialog = !this.voiceDialog;
        this.speakingItem = item;
        this.lastSentence = '';
        this.speaking = false;
    }
    toggleSpeak() {
        this.speaking = !this.speaking;
    }
    textToSpeech(text) {
        const speaknow = new SpeechSynthesisUtterance(text);
        speaknow.rate = 0.8;
        window.speechSynthesis.speak(speaknow);
    }
    async mounted() {
        this.loading = true;
        // @ts-ignore
        this.livro_codigo = this.$router.currentRoute.params.livro_codigo;
        const response = await livroStore.getGlossary(this.livro_codigo);
        this.livros = response.data;
        this.livro = response.livro;
        if (this.livros.length === 0) {
            this.$swal({
                // @ts-ignore
                title: `${this.palavrasTraduzidas[this.livro.curso.idioma].glossario} não encontrado`,
                text: 'Procure informações na recepção da sua unidade!',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok!',
            }).then(() => {
                // @ts-ignore
                this.$router.back();
            });
        }
        this.loading = false;
    }
};
Glossary = __decorate([
    Component
], Glossary);
export default Glossary;
